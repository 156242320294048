import React from "react";
import {
  Button,
  Column,
  Container,
  Row,
  Section,
  TextWrapper,
  SectionFooter,
} from "../../globalStyles";
import {
  FooterForm,
  FooterInput,
  FooterLogo,
  SocialIcon,
  WebsiteRights,
  FooterColumn,
  FooterSocialIcon,
  FooterRow,
  FooterLine,
  FooterIcon,
  FooterImage,
  FooterImageWrapper,
  FooterTextWrapper,
} from "./FooterStyles";
import { footerData, footerSocialData } from "../../data/footerData";
import "./buttonflash_white.css";
import { Link } from "react-router-dom";
import MiraRoadmap from "../MiraRoadmap/MiraRoadmap";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <SectionFooter padding="0 rem  0 rem">
      <Column
        justify="center"
        align="top"
        textAlign="center"
        mb="0rem"
        padding="1rem"
        bgcolor="#ff0000"
      >
        <TextWrapper
          size="3rem"
          weight="500"
          mb="1.2rem"
          color="white"
          padding="0.5rem"
          smSize="1rem"
        >
          Step into the future of AI power.
        </TextWrapper>
        <FooterTextWrapper>
          <TextWrapper
            mb="1rem"
            spacing="1px"
            color="white"
            size="1.5rem"
            smSize="1rem"
            padding="0.5rem"
          >
            MIRA ELITE solution, an AI-powered tool for the retail industry.
          </TextWrapper>
        </FooterTextWrapper>
        {/* Mira Elite Road Map Section */}
        <MiraRoadmap />
        <a
          target="_blank"
          href={"#"}
          style={{
            textDecoration: "none",
            color: "#fff",
          }}
        >
          <div className="heroneon_white">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Get in Touch
          </div>
        </a>
      </Column>
      <FooterRow>
        {footerData.map((el, index) => (
          <FooterColumn gap="0.5rem" key={index}>
            <Link to={el.link}>
              <FooterImageWrapper>
                <FooterImage src={el.image} alt="feature" />
              </FooterImageWrapper>
            </Link>
            <TextWrapper size="1.3rem" mb="0.4rem" smSize="1rem">
              {el.title}
            </TextWrapper>
            {/* {el.links.map((link, linkIndex) => (
              <TextWrapper key={linkIndex}>{link}</TextWrapper>
            ))} */}
          </FooterColumn>
        ))}
      </FooterRow>
      <Container>
        <FooterLine>
          <FooterLogo to="/">
            <FooterIcon src="/images/MIRA_horizontal.png" />
            <TextWrapper size="1rem" mb="1rem" mt="0.5rem" align="center">
              Mira Elite is a suite of AI-powered tools for the modern retail
              trades.
            </TextWrapper>
          </FooterLogo>
          <WebsiteRights>
            Copyright © {currentYear} Mira Elite. All right reserved{" "}
          </WebsiteRights>

          <Row justify="center" gap="2rem" align="center" width="240px">
            {footerSocialData.map((social, index) => (
              <FooterSocialIcon
                key={index}
                href="/"
                target="_blank"
                aria-label={social.name}
              >
                {social.icon}
              </FooterSocialIcon>
            ))}
          </Row>
        </FooterLine>
      </Container>
    </SectionFooter>
  );
}

export default Footer;
