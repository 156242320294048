import styled, { createGlobalStyle } from "styled-components";
import { motion } from "framer-motion";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro',sans-serif;
  }
`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin: ${({ margin }) => (margin ? margin : "0 auto")};
  padding: ${({ padding }) => (padding ? padding : "0 50px")};

  @media screen and (max-width: 960px) {
    padding-right: 0px;
    padding-left: 0px;
  }
`;

export const MainHeading = styled.h1`
  font-size: clamp(2.3rem, 6vw, 4.5rem);
  margin-bottom: 2rem;
  color: ${({ inverse }) => (inverse ? "$403ae3" : "#fff")};
  width: ${({ width }) => (width ? width : "")};
  text-align: center;
  letter-spacing: 4px;
`;

export const MainHeading_nail = styled.h1`
  font-size: clamp(3rem, 6vw, 4.5rem);
  margin-bottom: 1rem;
  background: -webkit-linear-gradient(135deg, #fed693, #ca7100);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 100%;
  text-align: center;
  letter-spacing: 4px;
  padding-left: 2rem;
  padding-right: 2rem;
  letter-spacing: 0.4rem;

  @media screen and (max-width: 960px) {
    padding-right: 0px;
    padding-left: 0px;
    // border: 1px solid green;
    margin-top: 0rem;
  }
`;

export const Heading = styled.h2`
  font-size: clamp(1.3rem, 6vw, 3.1rem);
  margin: ${({ margin }) => (margin ? margin : "")};
  margin-bottom: ${({ mb }) => (mb ? mb : "")};
  margin-top: ${({ mt }) => (mt ? mt : "")};
  color: ${({ inverse }) => (inverse ? "#403ae3" : "#fff")};
  letter-spacing: 0.4rem;
  text-align: center;
  width: ${({ width }) => (width ? width : "")};
`;

export const TextWrapper = styled.span`
  font-size: ${({ size }) => (size ? size : "")};
  font-weight: ${({ weight }) => (weight ? weight : "")};
  letter-spacing: ${({ spacing }) => (spacing ? spacing : "")};
  text-align: ${({ align }) => (align ? align : "")};
  padding: ${({ padding }) => (padding ? padding : "")};
  margin: ${({ margin }) => (margin ? margin : "")};
  margin-bottom: ${({ mb }) => (mb ? mb : "")};
  margin-top: ${({ mt }) => (mt ? mt : "")};
  width: ${({ width }) => (width ? width : "auto")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};
  height: ${({ height }) => (height ? height : "")};
  line-height: ${({ lineheight }) => (lineheight ? lineheight : "")};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "")};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "")};
  color: ${({ color }) => (color ? color : "")};

  // @media screen and (max-width: 768px) {
  // 	font-size: ${({ smSize }) => (smSize ? smSize : "")};
  // 	margin-bottom: ${({ smmb }) => (smmb ? smmb : "")};
  // 	margin-top: 0rem;
  // 	margin-bottom: 0rem;
  // }
`;

export const Section = styled.section`
  padding: ${({ padding }) => (padding ? padding : "140px 0")};
  margin: ${({ margin }) => (margin ? margin : "")};
  background: ${({ inverse }) => (inverse ? "#e9e9e9" : "#e9e9e9")};
  color: ${({ inverse }) => (inverse ? "black" : "black")};
  position: ${({ position }) => (position ? position : "")};
  width: ${({ width }) => (width ? width : "auto")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};
  height: ${({ height }) => (height ? height : "")};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "")};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "")};

  @media screen and (max-width: 768px) {
    padding: ${({ smPadding }) => (smPadding ? smPadding : "70px 0")};
  }
`;

export const SectionFooter = styled.section`
  padding: ${({ padding }) => (padding ? padding : "140px 0")};
  margin: ${({ margin }) => (margin ? margin : "")};
  background: ${({ inverse }) => (inverse ? "#e9e9e9" : "#101522")};
  color: ${({ inverse }) => (inverse ? "black" : "black")};
  position: ${({ position }) => (position ? position : "")};
  width: ${({ width }) => (width ? width : "auto")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};
  height: ${({ height }) => (height ? height : "")};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "")};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "")};

  @media screen and (max-width: 768px) {
    padding: ${({ smPadding }) => (smPadding ? smPadding : "0px 0")};
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : "")};
  align-items: ${({ align }) => (align ? align : "")};
  gap: ${({ gap }) => (gap ? gap : "")};
  padding: ${({ padding }) => (padding ? padding : "")};
  margin: ${({ margin }) => (margin ? margin : "")};
  margin-bottom: ${({ mb }) => (mb ? mb : "")};
  margin-top: ${({ mt }) => (mt ? mt : "")};
  position: ${({ position }) => (position ? position : "")};
  width: ${({ width }) => (width ? width : "100%")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};
  height: ${({ height }) => (height ? height : "")};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "")};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "")};
  flex-wrap: ${({ wrap }) => (wrap ? wrap : "")};
  color: ${({ color }) => (color ? color : "")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "")};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => (justify ? justify : "")};
  align-items: ${({ align }) => (align ? align : "")};
  gap: ${({ gap }) => (gap ? gap : "")};
  padding: ${({ padding }) => (padding ? padding : "")};
  margin: ${({ margin }) => (margin ? margin : "")};
  margin-bottom: ${({ mb }) => (mb ? mb : "")};
  margin-top: ${({ mt }) => (mt ? mt : "")};
  position: ${({ position }) => (position ? position : "")};
  width: ${({ width }) => (width ? width : "")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};
  height: ${({ height }) => (height ? height : "")};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "")};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "")};
  color: ${({ color }) => (color ? color : "")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "")};
  background-color: ${({ bgcolor }) => (bgcolor ? bgcolor : "")};
`;

export const Button = styled(motion.button)`
  border-radius: 4px;
  background: #ff0000;
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: ${({ red }) => (red ? "#ff0000" : "#fff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    transition: all 0.3s ease-out;
    /* background: #fff; */
    color: black;
    background-color: #ff0000;
  }
`;

export default GlobalStyle;
