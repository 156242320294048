import React, { useState, useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";
import {
  FormColumn,
  Img,
  ImgWrapper,
  FormWrapper,
  FormInput,
  FormSection,
  FormRow,
  FormTitle,
  FormLabel,
  FormInputRow,
  FormMessage,
  FormSub,
  FormSub2,
  FormTextarea,
  FormSelect,
} from "./FormStyles";
import { Container, Button } from "../../globalStyles";
import { white } from "../../Colors";
import validateForm from "./validateForm";
import { red } from "@mui/material/colors";
import toast, { Toaster } from "react-hot-toast"; // Import Toaster from react-hot-toast
import ProcessingBar from "../Processing Bar/ProcessingBar";
import Loader from "../Processing Bar/Loader";
import LoadingAnimation from "../Processing Bar/LoadingDot";
import LoadingScreen from "../Processing Bar/LoadingScreen";
import WineglassLoader from "../Processing Bar/WineglassLoader";
import RedirectingAnimation from "../Processing Bar/RedirectAnimation";

const Form = () => {
  // useEffect(() => {
  //   const hasScrolledBefore = localStorage.getItem("hasScrolledToTop");

  //   if (!hasScrolledBefore) {
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth",
  //     });
  //     localStorage.setItem("hasScrolledToTop", "true");
  //   }
  // }, []); // Empty dependency array ensures this runs only once on component mount
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [selectedService, setSelectedService] = useState("");

  const store = {
    YOUR_SERVICE_ID: "service_ebww48d",
    YOUR_TEMPLATE_ID: "template_pdv5cg9",
    YOUR_PUBLIC_KEY: "5t5zOM8-bVTxA2N7V",
  };

  const service = [
    {
      id: 1,
      name: "Website $999",
    },
    { id: 2, name: "QR Ordering" },
    { id: 3, name: "SEO" },
    { id: 4, name: "AI Service" },
    { id: 5, name: "Research" },
  ];
  const [loading, setLoading] = useState(false);

  const form = useRef(null); // Emailjs
  const handleSubmit = (e) => {
    e.preventDefault();
    const resultError = validateForm({
      name,
      email,
      phone,
      message,
      selectedService,
    });

    if (resultError !== null) {
      setError(resultError);
      return;
    }

    setLoading(true); // Start loading

    emailjs
      .sendForm(
        store.YOUR_SERVICE_ID,
        store.YOUR_TEMPLATE_ID,
        form.current,
        // templateParams,
        {
          publicKey: store.YOUR_PUBLIC_KEY,
        }
      )
      .then(
        (result) => {
          console.log("SUCCESS! -->", result.text);
          // setSuccess("Application was submitted!");
          toast.success(
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src="/images/icon_mira1.png" // Change this to your logo path
                alt="Logo"
                style={{
                  width: "auto",
                  height: "30px",
                  marginRight: "10px",
                  borderRadius: "50%",
                }}
              />
              <span>
                Your request has been submitted. Our staff will get in touch
                with you as soon as possible.
              </span>
            </div>,
            {
              icon: null, // No icon at all
            },
            { duration: 4000 }
          );
          setLoading(false);
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
          setError(null);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  const messageVariants = {
    hidden: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
  };

  const buttonVariants = {
    hover: {
      scale: 1.05,
      color: white,
      boxShadow: "0px 0px 8px white",
      transition: {
        duration: 0.3,
        yoyo: Infinity,
      },
    },
  };

  const formData = [
    {
      label: "Name",
      id: "input1",
      name: "user_name",
      value: name,
      onChange: (e) => setName(e.target.value),
      type: "text",
    },
    {
      label: "Email",
      id: "input2",
      name: "email",
      value: email,
      onChange: (e) => setEmail(e.target.value),
      type: "email",
    },
    {
      label: "Phone",
      id: "input3",
      name: "phone",
      value: phone,
      onChange: (e) => setPhone(e.target.value),
      type: "phone",
    },
    {
      label: "Select Service",
      id: "serviceSelect",
      name: "service",
      value: selectedService,
      onChange: (e) => setSelectedService(e.target.value),
      type: "select",
      options: service, // Pass the service array here
    },
    {
      label: "Message",
      name: "user_message",
      value: message,
      onChange: (e) => setMessage(e.target.value),
      type: "textarea",
    },
  ];
  return (
    <FormSection>
      <Container>
        <FormRow>
          <FormColumn>
            <ImgWrapper>
              <Img src="./images/music.svg" alt="music"></Img>
            </ImgWrapper>
          </FormColumn>
          <FormColumn small>
            <FormSub>Stay in touch with us</FormSub>
            <FormTitle>Quick Contact</FormTitle>
            <FormSub>
              <strong style={{ marginRight: "1rem" }}>Email: </strong>
              <span></span>info@miraelite.com
            </FormSub>
            <FormSub>
              <strong style={{ marginRight: "1rem" }}>Website: </strong>
              www.miraelite.com
            </FormSub>
            <FormSub2>Leave us a message</FormSub2>
            <FormWrapper onSubmit={handleSubmit} ref={form}>
              {formData.map((el, index) => (
                <FormInputRow key={index}>
                  <FormLabel>{el.label}</FormLabel>
                  {el.type === "textarea" ? (
                    <FormTextarea
                      placeholder={`Enter your ${el.label.toLocaleLowerCase()}`}
                      id={el.id}
                      name={el.name}
                      value={el.value}
                      onChange={(e) => {
                        el.onChange(e);
                        const textarea = e.target;
                        textarea.style.height = "auto";
                        textarea.style.height = `${textarea.scrollHeight}px`;
                      }}
                      rows={2} // optional, gives it a nice starting height
                    />
                  ) : el.type === "select" ? (
                    <FormSelect
                      id={el.id}
                      name={el.name}
                      value={el.value}
                      onChange={el.onChange}
                    >
                      <option value="">Select a service</option>
                      {el.options.map((srv) => (
                        <option key={srv.id} value={srv.name}>
                          {srv.name}
                        </option>
                      ))}
                    </FormSelect>
                  ) : (
                    <FormInput
                      id={el.id}
                      name={el.name}
                      type={el.type}
                      placeholder={`Enter your ${el.label.toLocaleLowerCase()}`}
                      value={el.value}
                      onChange={el.onChange}
                    />
                  )}
                </FormInputRow>
              ))}

              <Button
                variants={buttonVariants}
                whileHover="hover"
                type="submit"
              >
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <RedirectingAnimation />
                  </div>
                ) : (
                  <p>Submit</p>
                )}
              </Button>
            </FormWrapper>
            {/* {loading && <ProcessingBar />} */}
            {error && (
              <FormMessage
                variants={messageVariants}
                initial="hidden"
                animate="animate"
                error
              >
                {error}
              </FormMessage>
            )}
            {success && (
              <FormMessage
                variants={messageVariants}
                initial="hidden"
                animate="animate"
              >
                {success}
              </FormMessage>
            )}
          </FormColumn>
        </FormRow>
      </Container>
    </FormSection>
  );
};

export default Form;
