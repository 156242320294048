import React from "react";
import styled, { createGlobalStyle, keyframes, css } from "styled-components";

// Define your colors
const black = "#000";
const white = "#fff";

// Keyframes
const dotMove = keyframes`
  0%, 100% {
    transform: rotate(180deg) translate(-110px, -10px) rotate(-180deg);
  }
  50% {
    transform: rotate(0deg) translate(-111px, 10px) rotate(0deg);
  }
`;

const letterStretch = keyframes`
  0%, 100% {
    transform: scale(1, 0.35);
    transform-origin: 100% 75%;
  }
  8%, 28% {
    transform: scale(1, 2.125);
    transform-origin: 100% 67%;
  }
  37% {
    transform: scale(1, 0.875);
    transform-origin: 100% 75%;
  }
  46% {
    transform: scale(1, 1.03);
    transform-origin: 100% 75%;
  }
  50%, 97% {
    transform: scale(1);
    transform-origin: 100% 75%;
  }
`;

const lineStretch = keyframes`
  0%, 45%, 70%, 100% {
    transform: scaleY(0.125);
  }
  49% {
    transform: scaleY(0.75);
  }
  50% {
    transform: scaleY(0.875);
  }
  53% {
    transform: scaleY(0.5);
  }
  60% {
    transform: scaleY(0);
  }
  68% {
    transform: scaleY(0.18);
  }
`;

// Global styles

// Styled components
const Container = styled.div`
  position: relative;
  transform: scale(0.725);

  @media (min-width: 48rem) {
    transform: scale(0.725rem);
  }

  @media (min-width: 62rem) {
    transform: scale(0.85);
  }
`;

const Divider = styled.div`
  position: absolute;
  z-index: 2;
  top: 65px;
  left: 200px;
  width: 50px;
  height: 15px;
  background: ${white};
`;

const LoadingText = styled.p`
  position: relative;
  font-size: 0.8rem;
  font-weight: 300;
  margin: 0;
  white-space: nowrap;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 40px;
    left: 115px;
    width: 6px;
    height: 6px;
    background: ${black};
    border-radius: 50%;
    animation: ${dotMove} 1800ms cubic-bezier(0.25, 0.25, 0.75, 0.75) infinite;
  }
`;

const Letter = styled.span`
  display: inline-block;
  position: relative;
  color: ${black};
  letter-spacing: 8px;

  ${({ index }) => {
    if (index === 1) {
      // Letter "L"
      return css`
        transform-origin: 100% 70%;
        transform: scale(1, 1.275);

        &::before {
          content: "";
          position: absolute;
          top: 22px;
          left: 0;
          width: 14px;
          height: 36px;
          background: ${white};
          transform-origin: 100% 0;
          animation: ${lineStretch} 1800ms cubic-bezier(0.25, 0.25, 0.75, 0.75)
            infinite;
        }
      `;
    }

    if (index === 5) {
      // Letter "i"
      return css`
        transform-origin: 100% 70%;
        animation: ${letterStretch} 1800ms cubic-bezier(0.25, 0.23, 0.73, 0.75)
          infinite;

        &::before {
          content: "";
          position: absolute;
          top: 15px;
          left: 2px;
          width: 9px;
          height: 15px;
          background: ${white};
        }
      `;
    }

    return "";
  }}
`;

// React component
const LoadingAnimation = () => {
  const text = "Mira Elite";
  return (
    <>
      <Container>
        <Divider aria-hidden="true" />
        <LoadingText aria-label="Mira Elite">
          {text.split("").map((letter, index) => (
            <Letter key={index} index={index} aria-hidden="true">
              {letter}
            </Letter>
          ))}
        </LoadingText>
      </Container>
    </>
  );
};

export default LoadingAnimation;
