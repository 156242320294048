import React from "react";
import styled, { createGlobalStyle, keyframes } from "styled-components";

// Global Styles (body styles and background)

// Animations
const rotate1 = keyframes`
  0% { transform: rotate(0deg); }
  50% { transform: rotate(22deg); }
`;

const rotate2 = keyframes`
  0% { transform: rotate(0deg); }
  50% { transform: rotate(-22deg); }
`;

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: scaleY(1);
  }
  33% {
    opacity: 1;
    transform: scaleY(1.4);
  }
  64% {
    opacity: 0.1;
    transform: scaleY(1);
  }
  100% { 
    opacity: 0;
    transform: scaleY(0.3);
  }
`;

// Styled Components
const Loader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wineglass = styled.div`
  height: 10px;
  position: relative;
  display: inline-block;
  margin-right: ${({ side }) => (side === "left" ? "10px" : "0")};

  animation: ${({ side }) => (side === "left" ? rotate1 : rotate2)} 2s
    cubic-bezier(0.39, 1.52, 0.46, 0.92) infinite;

  &::after {
    content: "";
    position: absolute;
    top: 47px;
    left: 5px;
    width: 20px;
    height: 5px;
    background: white;
    box-shadow: 0 0 1px white;
  }
`;

const Top = styled.div`
  background: white;
  width: 30px;
  height: 36px;
  border-radius: 0 0 36px 36px;
  box-shadow: 0 0 1px white;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 4px;
    bottom: 4px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: linear-gradient(transparent 50%, #f36e3e 50%);
    animation: ${({ side }) => (side === "left" ? rotate2 : rotate1)} 2s linear
      infinite;
  }

  &::after {
    content: "";
    position: absolute;
    top: 35px;
    left: 12px;
    width: 6px;
    height: 13px;
    background: white;
    box-shadow: 0 0 1px white;
  }
`;

const BubbleList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 70px;
  height: 25px;
  position: relative;
  opacity: 0;
  animation: ${fadeInUp} 2s infinite linear 2s;
`;

const Bubble = styled.li`
  width: 6px;
  height: 15px;
  background: white;
  position: absolute;
  box-shadow: 0 0 1px white;
  transform-origin: bottom;

  &:nth-child(1) {
    left: 26px;
    bottom: 5px;
    transform: rotate(-35deg);
  }

  &:nth-child(2) {
    left: 34px;
    bottom: 8px;
  }

  &:nth-child(3) {
    left: 42px;
    bottom: 5px;
    transform: rotate(35deg);
  }
`;

// React Component
const WineglassLoader = () => {
  return (
    <>
      <Loader>
        <BubbleList>
          <Bubble />
          <Bubble />
          <Bubble />
        </BubbleList>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Wineglass side="left">
            <Top side="left" />
          </Wineglass>

          <Wineglass side="right">
            <Top side="right" />
          </Wineglass>
        </div>
      </Loader>
    </>
  );
};

export default WineglassLoader;
