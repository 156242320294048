import React from "react";
import styled, { keyframes } from "styled-components";

// Constants
const text = "Mira Elite...";
const rings = 2;
const ringSectors = 30;

// Keyframes (Animations)
const tiltSpin = keyframes`
  from {
    transform: rotateY(0) rotateX(30deg);
  }
  to {
    transform: rotateY(1turn) rotateX(30deg);
  }
`;

const spin = keyframes`
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(1turn);
  }
`;

// Styled components
const PreloaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  background: #000;
  color: #000;
  font-family: Dosis, sans-serif;
  line-height: 1.5;
  perspective: 40em;
  font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320));
`;

const Preloader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 17em;
  height: 17em;
  animation: ${tiltSpin} 8s linear infinite;
  transform-style: preserve-3d;
`;

const Ring = styled.div`
  position: relative;
  height: 3rem;
  width: 1.5rem;
  font-size: 2em;
  transform-style: preserve-3d;
  animation: ${spin} 4s linear infinite;

  &:nth-child(even) {
    animation-direction: reverse;
  }
`;

const Sector = styled.div`
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
  height: 100%;

  ${({ index }) => {
    if (index === 0) {
      return `
        transform: translateZ(7rem);
      `;
    }
    const angle = (360 / ringSectors) * index;
    return `
      transform: rotateY(${angle}deg) translateZ(7rem);
    `;
  }}

  &::before {
    ${({ children }) =>
      !children
        ? `
      content: "";
      display: inline-block;
      width: 100%;
      height: 100%;
      background: linear-gradient(transparent 45%, currentColor 45% 55%, transparent 55%);
    `
        : ""}
  }
`;

// React component
const Loader = () => {
  return (
    <PreloaderWrapper>
      <Preloader>
        {Array.from({ length: rings }).map((_, rIndex) => (
          <Ring key={rIndex}>
            {Array.from({ length: ringSectors }).map((_, sIndex) => (
              <Sector key={sIndex} index={sIndex}>
                {text[sIndex] || ""}
              </Sector>
            ))}
          </Ring>
        ))}
      </Preloader>
    </PreloaderWrapper>
  );
};

export default Loader;
