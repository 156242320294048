import styled from "styled-components";
import { Link as LinkScroll } from "react-scroll";
import { Container } from "../../globalStyles";
import { pink, orange } from "../../Colors";
import { Link } from "react-router-dom";

export const Nav = styled.nav`
  background: ${({ background }) => (background ? "#101522" : "transparent")};
  // background: ${({ background }) =>
    background ? "#e9e9e9" : "transparent"};
  margin-bottom: -80px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 1002;

  transition: background-color 0.3s ease-in;
`;

export const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: start;
  height: 60px;

  ${Container}
`;

export const NavLogo = styled(Link)`
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;

  z-index: 100;
  background: transparent;
  width: 20rem;

  display: flex;
  align-items: center;
  justify-content: start;
`;

export const NavIcon = styled.img`
  margin-right: 0.5rem;
  width: 5rem;

  @media screen and (max-width: 960px) {
    width: 5rem;
    margin-left: 2rem;
  }
`;

export const MobileIcon = styled.div`
  display: none;
  z-index: 100;

  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    align-items: center; /* Fixed typo here */
    justify-content: center;

    border: 1px solid #c1c1c1;
    border-radius: 20px;

    width: 90%;
    max-width: 400px;
    height: 90%;
    min-height: 75vh;

    position: absolute;
    padding: 50px;
    top: ${({ show }) => (show ? "6rem" : "-100vh")};
    left: calc(50% - 190px);
    opacity: 1;
    transition: all 0.5s ease;
    background: #101522;

    pointer-events: ${({ show }) => (show ? "auto" : "none")};
  }

  > li:first-child {
    margin-left: auto;
  }
`;
// export const NavMenu = styled.ul`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   list-style: none;
//   text-align: center;
//   width: 100%;

//   @media screen and (max-width: 960px) {
//     display: flex;
//     flex-direction: column;
//     align-items: center; /* Fixed typo here */
//     justify-content: center;

//     position: absolute;
//     top: 100%;
//     left: 50%;
//     transform: translate(-50%, -50%); /* Center horizontally & vertically */

//     width: 95%;
//     max-width: 400px;
//     height: 60vh;

//     padding: 40px 20px;
//     border: 2px solid #fff;
//     border-radius: 20px;

//     opacity: ${({ show }) => (show ? 1 : 0)};
//     transition: all 0.5s ease;
//     background: #101522;

//     /* Optional: Slide in effect */
//     transform: ${({ show }) =>
//       show ? "translate(-50%, -50%)" : "translate(-50%, -150%)"};
//     pointer-events: ${({ show }) => (show ? "auto" : "none")};
//   }

//   > li:first-child {
//     margin-left: auto;
//   }
// `;
export const NavItem = styled.li`
  height: 50px;
  cursor: pointer;

  @media screen and (max-width: 960px) {
    width: 100%;
    // border-top: 1px dashed #fff;
    height: 50px;
    display: flex;
    align-items: center;
    &:hover {
      border: none;
    }
  }
`;

export const NavItemBtn = styled.li`
  @media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
  margin-left: auto;

  button {
    background-color: #ff0000;
    // border-radius: 5px;
    // border: 2px solid ${orange};
    max-width: 200px;

    @media screen and (max-width: 960px) {
      width: 100%;
    }
  }
`;

export const NavLinks = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  &.active {
    border-bottom: 2px solid #ebc214;
  }
  &:hover {
    border-bottom: 2px solid ${pink};
  }

  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover {
      color: #4b59f7;
      transition: all 0.3s ease;
    }
  }
`;

export const NavBtnLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
`;
