export default function validateForm({
  name,
  email,
  phone,
  message,
  selectedService,
}) {
  if (!name.trim()) {
    return "Name required";
  }

  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email) {
    return "Email required";
  } else if (regex.test(email.toLocalLowerCase)) {
    return "Email address is invalid";
  }

  if (!phone) {
    return "Phone is required";
  } else if (phone.length < 10) {
    return "Phone needs to be 10 numbers or more";
  }

  if (!message) {
    return "Message is required";
  }

  if (!selectedService) {
    return "Service is required";
  }
  return null;
}
