import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
// import { Tooltip } from "@material-tailwind/react";
import "./popup.css";
import toast, { Toaster } from "react-hot-toast"; // Import Toaster from react-hot-toast
import validateForm from "../Form/validateForm";
import emailjs from "@emailjs/browser";
import ProcessingBar from "../Processing Bar/ProcessingBar";
import Loader from "../Processing Bar/Loader";
import LoadingScreen from "../Processing Bar/LoadingScreen";

const Modal = ({ open, setOpen }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [selectedService, setSelectedService] = useState("");
  const form = useRef(null); // Emailjs
  //processing bar
  const [loading, setLoading] = useState(false);

  const store = {
    YOUR_SERVICE_ID: "service_ebww48d",
    YOUR_TEMPLATE_ID: "template_pdv5cg9",
    YOUR_PUBLIC_KEY: "5t5zOM8-bVTxA2N7V",
  };

  const service = [
    {
      id: 1,
      name: "Website $999",
    },
    { id: 2, name: "QR Ordering" },
    { id: 3, name: "SEO" },
    { id: 4, name: "AI Service" },
    { id: 5, name: "Research" },
  ];
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name || !email || !phone || !selectedService || !message) {
      toast.error("Please fill out all required fields!");
      return;
    }

    setLoading(true);
    emailjs
      .sendForm(store.YOUR_SERVICE_ID, store.YOUR_TEMPLATE_ID, form.current, {
        publicKey: store.YOUR_PUBLIC_KEY,
      })
      .then(
        (result) => {
          console.log("SUCCESS! -->", result.text);
          // setSuccess("Application was submitted!");
          toast.success(
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src="/images/icon_mira1.png" // Change this to your logo path
                alt="Logo"
                style={{
                  width: "auto",
                  height: "30px",
                  marginRight: "10px",
                  borderRadius: "50%",
                }}
              />
              <span>
                Your request has been submitted. Our staff will get in touch
                with you as soon as possible.
              </span>
            </div>,
            {
              icon: null, // No icon at all
            },
            { duration: 4000 }
          );

          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
          setError(null);
          setOpen(false);
          setLoading(false);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  const formData = [
    {
      label: "Name (*)",
      id: "input1",
      name: "user_name",
      value: name,
      onChange: (e) => setName(e.target.value),
      type: "text",
    },
    {
      label: "Email (*)",
      id: "input2",
      name: "email",
      value: email,
      onChange: (e) => setEmail(e.target.value),
      type: "email",
    },
    {
      label: "Phone (*)",
      id: "input3",
      name: "phone",
      value: phone,
      onChange: (e) => setPhone(e.target.value),
      type: "phone",
    },
    {
      label: "Select Service (*)",
      id: "serviceSelect",
      name: "service",
      value: selectedService,
      onChange: (e) => setSelectedService(e.target.value),
      type: "select",
      options: service, // Pass the service array here
    },
    {
      label: "Message (*)",
      name: "user_message",
      value: message,
      onChange: (e) => setMessage(e.target.value),
      type: "textarea",
    },
  ];
  return (
    <div>
      <motion.div
        className="modalContainer"
        initial={{ scale: 1, opacity: 0, height: 0, width: 0 }}
        animate={{
          x: open ? -30 : 0,
          y: open ? -30 : 0,
          width: open && "350px",
          height: open && "600px",
          opacity: 1,
        }}
        transition={{ type: "spring", duration: 2, ease: "easeInOut" }}
      >
        <motion.div
          className="modalContainerInside"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ type: "Tween", duration: 2 }}
        >
          <h4 className="modalh5">REQUEST A QUOTE</h4>
          <p className="modalp">Hello There 🖐🏻</p>
          <form className="modalform" onSubmit={handleSubmit} ref={form}>
            {formData.map((field, index) => (
              <div className="form-input-row" key={index}>
                {field.type === "textarea" ? (
                  <textarea
                    id={field.id}
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    className="formTextarea"
                    placeholder={`${field.label.toLowerCase()}`}
                    rows={5}
                    style={{ resize: "none" }}
                  />
                ) : field.type === "select" ? (
                  <select
                    id={field.id}
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    className="formSelect"
                  >
                    <option value="">service</option>
                    {field.options.map((option) => (
                      <option
                        key={option.id}
                        value={option.name}
                        style={{ maxWidth: "100px" }}
                      >
                        {option.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    value={field.value}
                    onChange={field.onChange}
                    className="formInput"
                    placeholder={`${field.label.toLowerCase()}`}
                  />
                )}
              </div>
            ))}

            <button type="submit" className="submit-button">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LoadingScreen />
                </div>
              ) : (
                <p>Submit</p>
              )}
            </button>
          </form>

          <div className="modalX" onClick={() => setOpen(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#fff"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#000"
              className="modalSvg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Modal;
