import React from "react";
import styled, { createGlobalStyle, keyframes, css } from "styled-components";

// Animations
const movingLine = keyframes`
  0% {
    opacity: 0;
    width: 0;
  }

  33.3%, 66% {
    opacity: 0.8;
    width: 100%;
  }
  
  85% {
    width: 0;
    left: initial;
    right: 0;
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 0;
  }
`;

const moveLetters = keyframes`
  0% {
    transform: translateX(-5vw);
    opacity: 0;
  }

  33.3%, 66% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(5vw);
    opacity: 0;
  }
`;

// Styled Components
const LoadingContainer = styled.div`
  width: 100%;
  margin-left: 0rem;
  max-width: 200px;
  text-align: center;
  color: #fff;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #fff;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    animation: ${movingLine} 2.4s infinite ease-in-out;
  }
`;

const LoadingText = styled.div`
  font-size: 1rem;
  line-height: 20px;
  letter-spacing: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-evenly;

  span {
    animation: ${moveLetters} 2.4s infinite ease-in-out;
    transform: translateX(0);
    position: relative;
    display: inline-block;
    opacity: 0;
    text-shadow: 0px 2px 10px rgba(46, 74, 81, 0.3);
  }
`;

// Create styled spans with staggered animation delays
const AnimatedLetter = styled.span`
  ${({ index }) => css`
    animation-delay: ${index * 0.1}s;
  `}
`;

const Socials = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
  display: flex;
  align-items: center;
`;

const SocialLink = styled.a`
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  margin-right: 12px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

// React Component
const LoadingScreen = () => {
  const letters = "...SENDING...".split("");

  return (
    <>
      <LoadingContainer>
        <LoadingText>
          {letters.map((letter, index) => (
            <AnimatedLetter key={index} index={index}>
              {letter}
            </AnimatedLetter>
          ))}
        </LoadingText>
      </LoadingContainer>

      <Socials>
        <SocialLink
          href="https://twitter.com/aybukeceylan"
          target="_top"
          aria-label="Twitter"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
          </svg>
        </SocialLink>

        <SocialLink
          href="https://www.linkedin.com/in/ayb%C3%BCkeceylan/"
          target="_top"
          aria-label="LinkedIn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
            <rect x="2" y="9" width="4" height="12" />
            <circle cx="4" cy="4" r="2" />
          </svg>
        </SocialLink>
      </Socials>
    </>
  );
};

export default LoadingScreen;
