import React from "react";
import Form from "../../components/Form/Form";
import Transition from "../../hook/Transition";
const Signup = () => {
  return (
    <>
      <Transition />
      <Form />;
    </>
  );
};

export default Signup;
